'use strict';

angular.module('enervexSalesappApp').controller('ResidentialCompanyTypesCtrl', function($scope, ResidentialCompanyType, _) {
	$scope.all = [];
	$scope.offset = 0;
	$scope.limit = 50;
	function fetch() {
		ResidentialCompanyType.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(result){
			if (result.length > 0) {
				$scope.all = $scope.all.concat(result);
				$scope.offset = $scope.offset + $scope.limit;
				$scope.all = _.sortBy($scope.all, function(o){
					return o.sortOrder * 1
				})
				fetch();
			} else {
				$scope.residentialCompanyTypes = $scope.all
			}
		});
	}
	fetch();
	$scope.delete = function(item) {
		ResidentialCompanyType.delete({
			id: item._id
		});
		$scope.all = _.filter($scope.all, function(r){
			return r._id != item._id
		})
		$scope.residentialCompanyTypes = $scope.all;
	}
});
